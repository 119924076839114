<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { unflatten } from 'flat'
import { get, capitalize } from 'lodash'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useExtendedI18n } from '@/i18n'
import { useModal } from '@/modules/shared/composables/use-modal'
import { createOptions, markDisabledOptions } from '@/modules/shared/utils/form'
import { rails_url } from '@/modules/shared/utils/rails'
import { toNumber } from '@/modules/shared/utils/money'
import { VButton, VIcon, VSection, VTable, VModal, VTextField } from '@/modules/shared/components'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import OwnershipTransferSummary from '../components/ownership-transfer-summary.vue'
import TheHeader from '../components/the-header.vue'
import TheNav from '../components/the-nav.vue'
import VSelect from '../components/v-select.vue'
import { useCommitmentStore } from '../stores/commitment-store'
import { useEntityStore } from '../stores/entity-store'
import { useInvestorStore } from '@/modules/entity/stores/investor-store'
import { ownership_transfer_form_initial_state, useOwnershipTransferStore } from '../stores/ownership-transfer-store'
import { useInvestingVehicleStore } from '../stores/vehicle-store'
import { getOwnershipTransferSummary } from '../utils/ownership-transfer'
import { investorPath } from '../utils/investor'

///////////////////////////////////////////////////////////////////////////////
// UTILS
///////////////////////////////////////////////////////////////////////////////

const getRubyType = (ruby_type: string): string => {
  switch (ruby_type) {
    case 'individual':
      return 'investors'
      break
    case 'fundingentity':
      return 'funding-entities'
      break
    default:
      return 'funds'
      break
  }
}

const route = useRoute()
const { n, t } = useExtendedI18n()
const skeleton = ref(true)
const entityStore = useEntityStore()
const ownershipTransferStore = useOwnershipTransferStore()
const investorStore = useInvestorStore()
const commitmentStore = useCommitmentStore()
const vehicleStore = useInvestingVehicleStore()
const cid = computed(() => `${route.params.entity_type}:${route.params.entity_id}`)
const isAdmin = computed(() => vehicleStore.items.get(cid.value) === 'admin')

const currentEntity = ref(null)
const currentTransfers = computed(() =>
  ownershipTransferStore.ownership_transfers.filter(
    (transfer) =>
      investorStore.state.selectedKeys.includes(transfer.seller_commitment.investor._cid) ||
      investorStore.state.selectedKeys.includes(transfer.buyer_commitment.investor._cid),
  ),
)

const sellerTranferOptions = computed(() => {
  const disabledInvestors = [transferOwnership.value.buyer_investor_id]
  const items = commitmentStore.commitments.reduce((list, commitment) => {
    list[commitment.investor._cid] = commitment.investor
    return list
  }, {})

  return markDisabledOptions(createOptions(items, { label: 'name' }), disabledInvestors)
})

const buyerTranferOptions = computed(() => {
  const disabledInvestors = [transferOwnership.value.seller_investor_id]

  return markDisabledOptions(createOptions(investorStore.state.items, { label: 'name' }), disabledInvestors)
})

///////////////////////////////////////////////////////////////////////////////
// VIEW TRANSFER
///////////////////////////////////////////////////////////////////////////////
const viewTransferModal = useModal()
const selected_transfer = ref(null)

const openViewTransferModal = (item) => {
  const ownership_transfer = ownershipTransferStore.items.get(item.id)
  selected_transfer.value = getOwnershipTransferSummary(
    ownership_transfer.seller_commitment,
    ownership_transfer.buyer_commitment,
    ownership_transfer.commitment_purchased_percent,
    toNumber(ownership_transfer.disbursement.capital),
    toNumber(ownership_transfer.disbursement.carried_interest),
    toNumber(ownership_transfer.disbursement.other_fees),
    ownership_transfer.commitment_purchased.currency,
  )
  viewTransferModal.open()
}

///////////////////////////////////////////////////////////////////////////////
// ADD TRANSFER
///////////////////////////////////////////////////////////////////////////////
const transferFormRules = {
  buyer_investor_id: { required },
  seller_investor_id: { required },
  transfer_percentage: { required },
  amount_paid: { required },
  date: { required },
}

const transferOwnership = ref({ ...ownership_transfer_form_initial_state })
const vto$ = useVuelidate(transferFormRules, transferOwnership, { $lazy: true })
const addTransferModal = useModal()
const openAddTransferModal = () => {
  vto$.value.$reset()
  transferOwnership.value = { ...ownership_transfer_form_initial_state }
  addTransferModal.open()
}

const ownershipTransferFormSummary = computed(() => {
  const seller_cid = transferOwnership.value.seller_investor_id
  const seller = commitmentStore.items.get(seller_cid)
  const buyer_cid = transferOwnership.value.buyer_investor_id
  const buyer = commitmentStore.items.get(buyer_cid)
  const transferred_percent = (transferOwnership.value.transfer_percentage || 0) / 100

  return getOwnershipTransferSummary(
    seller,
    buyer,
    transferred_percent,
    transferOwnership.value.amount_paid,
    transferOwnership.value.carried_interest_amount,
    transferOwnership.value.other_fee,
    currentEntity.value.currency,
  )
})

const canSellerTransfer = computed(() => {
  if (!transferOwnership.value.seller_investor_id) return true
  const seller = commitmentStore.items.get(transferOwnership.value.seller_investor_id)

  return !seller.has_scheduled_calls && !seller.has_outstanding_invoices
})

async function transferOwnershipSubmit() {
  if (!canSellerTransfer) return

  const valid = await vto$.value.$validate()
  if (!valid) return
  await ownershipTransferStore.transferOwnership(
    {
      buyer_investor_id: transferOwnership.value.buyer_investor_id,
      seller_investor_id: transferOwnership.value.seller_investor_id,
      transfer_percentage: transferOwnership.value.transfer_percentage,
      amount_paid: transferOwnership.value.amount_paid || 0,
      carried_interest_amount: transferOwnership.value.carried_interest_amount || 0,
      other_fee: transferOwnership.value.other_fee || 0,
      date: transferOwnership.value.date,
    },
    route.params.entity_type,
    route.params.entity_id,
  )
  await ownershipTransferStore.fetchOwnershipTransfers(route.params.entity_type, route.params.entity_id)
  await commitmentStore.fetchCommitments(route.params.entity_type, route.params.entity_id)
  addTransferModal.close()
}

///////////////////////////////////////////////////////////////////////////////
// REMOVE TRANSFER
///////////////////////////////////////////////////////////////////////////////

const removeTransfer = async (item) => {
  if (!window.confirm('Are you sure?')) return
  await ownershipTransferStore.removeOwnershipTransfer(item.id, route.params.entity_type, route.params.entity_id)
  await commitmentStore.fetchCommitments(route.params.entity_type, route.params.entity_id)
}

onMounted(async () => {
  await entityStore.fetchEntityInfo({
    type: route.params.entity_type as string,
    id: route.params.entity_id as string,
  })

  await Promise.all([
    vehicleStore.fetchVehicles(),
    ownershipTransferStore.fetchOwnershipTransfers(route.params.entity_type, route.params.entity_id),
    investorStore.list(),
    commitmentStore.fetchCommitments(route.params.entity_type, route.params.entity_id),
  ])

  currentEntity.value = entityStore.items.get(`${route.params.entity_type}:${route.params.entity_id}`)
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <TheHeader />
    <TheNav />
    <div class="-mt-7 mb-5 flex justify-end">
      <VButton size="md" variant="v-blue" :click="() => openAddTransferModal()" v-if="isAdmin">
        <div class="mr-1 flex items-center space-x-2">
          <div><VIcon name="plus" /></div>
          <div>Transfer</div>
        </div>
      </VButton>
    </div>
    <VTable
      :columns="[
        {
          key: 'transfer_name',
          name: capitalize(t('shared.transfer')),
          type: 'string',
          align: 'left',
          fixed: true,
          is_visible: true,
        },
        {
          key: 'date',
          name: capitalize(t('shared.date')),
          sorted: true,
          type: 'date',
          align: 'left',
          is_visible: true,
        },
        {
          key: 'disbursement.capital',
          name: capitalize(t('shared.amount paid')),
          type: 'currency',
          align: 'left',
          is_visible: true,
        },
        {
          key: 'actions',
          name: '',
          type: 'actions',
          align: 'right',
          is_visible: true,
        },
      ]"
      :items="currentTransfers"
      :name="`${route.params.entity_type}-${route.params.entity_id}-transfers`"
      :skeleton="skeleton"
      :slots="['actions', 'transfer_name']"
    >
      <template #transfer_name="{ item }">
        <RouterLink
          class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
          :to="investorPath(get(item, 'seller_commitment.investor._cid'))"
          v-if="isAdmin"
        >
          {{ get(item, 'seller_commitment.investor.name') }}
        </RouterLink>
        <span v-else> {{ get(item, 'seller_commitment.investor.name') }} </span>
        ->
        <RouterLink
          class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
          :to="investorPath(get(item, 'buyer_commitment.investor._cid'))"
          v-if="isAdmin"
        >
          {{ get(item, 'buyer_commitment.investor.name') }}
        </RouterLink>
        <span v-else> {{ get(item, 'buyer_commitment.investor.name') }} </span>
      </template>
      <template #actions="{ item }">
        <div class="flex gap-3">
          <VButton size="xs" :click="() => openViewTransferModal(item)">
            {{ capitalize(t('shared.view')) }}
          </VButton>
          <VButton size="xs" :click="() => removeTransfer(item)" v-if="isAdmin">
            {{ capitalize(t('shared.remove')) }}
          </VButton>
        </div>
      </template>
    </VTable>
    <VModal :modalStore="viewTransferModal">
      <template #main>
        <OwnershipTransferSummary :ownership_transfer="selected_transfer" />
      </template>
    </VModal>
    <VModal :modalStore="addTransferModal" id="transferOwnershipFormModal" :has_footer="false">
      <template #main>
        <div class="-mx-6 -my-6 border-b-[1px] border-gray-300 px-6 py-3 text-lg font-semibold">Transfer Ownership</div>
        <div class="mt-9 flex h-[calc(100%-1.8rem)] gap-5">
          <div class="flex flex-col gap-3 pb-6">
            <VSection class="w-[30rem] flex-grow">
              <div class="space-y-3">
                <VSelect
                  v-model="transferOwnership.seller_investor_id"
                  :label="capitalize(t('shared.who is the seller?'))"
                  :options="sellerTranferOptions"
                  property="seller_investor_id"
                  :v$="vto$"
                  class="z-[1]"
                />
                <VSelect
                  v-model="transferOwnership.buyer_investor_id"
                  :label="capitalize(t('shared.who is the buyer?'))"
                  :options="buyerTranferOptions"
                  property="buyer_investor_id"
                  :v$="vto$"
                />
                <VTextField
                  v-model="transferOwnership.date"
                  :label="capitalize(t('shared.date'))"
                  type="date"
                  property="date"
                  :v$="vto$"
                />
                <VTextField
                  v-model="transferOwnership.transfer_percentage"
                  :label="capitalize(t('shared.what % of the seller’s position is being bought?'))"
                  :placeholder="n(0, 'percent')"
                  property="transfer_percentage"
                  type="number"
                  :v$="vto$"
                />
                <VTextField
                  v-model="transferOwnership.amount_paid"
                  :label="capitalize(t('shared.how much is being paid for it?'))"
                  :placeholder="n({ amount: '0', currency: currentEntity.currency, date: null }, 'currency')"
                  property="amount_paid"
                  type="number"
                  :v$="vto$"
                />
                <VTextField
                  v-model="transferOwnership.carried_interest_amount"
                  :label="capitalize(t('shared.is there any carried interest being paid?'))"
                  :placeholder="n({ amount: '0', currency: currentEntity.currency, date: null }, 'currency')"
                  property="carried_interest_amount"
                  type="number"
                  :v$="vto$"
                />
                <VTextField
                  v-model="transferOwnership.other_fee"
                  :label="capitalize(t('shared.any other fees being paid as part of this transaction?'))"
                  :placeholder="n({ amount: '0', currency: currentEntity.currency, date: null }, 'currency')"
                  property="other_fee"
                  type="number"
                  :v$="vto$"
                />
              </div>
            </VSection>
            <div>
              <div class="mb-1 text-center text-sm text-red-500" v-if="!canSellerTransfer">
                Seller has scheduled calls or outstanding invoices, cannot make the transfer
              </div>
              <VButton
                :disabled="!canSellerTransfer"
                :click="transferOwnershipSubmit"
                variant="primary"
                size="lg"
                class="w-full"
                >Transfer Ownership</VButton
              >
            </div>
          </div>
          <div class="-my-3 -mr-6 w-[32.5rem] overflow-y-auto bg-gray-100 px-5 py-3">
            <OwnershipTransferSummary :ownership_transfer="ownershipTransferFormSummary" />
          </div>
        </div>
      </template>
    </VModal>
  </TheLayout>
</template>

<style>
#transferOwnershipFormModal .dialog-panel-container {
  @apply !w-fit !max-w-fit;
}

#transferOwnershipFormModal .dialog-panel-container .main-div {
  @apply !overflow-y-hidden;
}
</style>
