<script setup lang="ts">
import { format } from 'date-fns'
import { capitalize, get } from 'lodash'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import {
  PortfolioSection,
  VAvatar,
  VButton,
  VIcon,
  VTextField,
  VDropdownImproved,
  VTable,
} from '@/modules/shared/components'
import { sum, toNumber } from '@/modules/shared/utils/money'
import { rails_url } from '@/modules/shared/utils/rails'
import { roi } from '@/modules/shared/utils/reporting'
import { parse } from '@/modules/shared/utils/v-table'
import { useWorkspaceStore } from '@/modules/workspace/stores/workspace-store'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import TheHeader from '../components/the-header.vue'
import TheNav from '../components/the-nav.vue'
import { usePortfolioStore } from '../stores/portfolio-store'
import { useInvestingVehicleStore } from '../stores/vehicle-store'

const { t } = useI18n()

const authStore = useAuthStore()
const vehicleStore = useInvestingVehicleStore()

const skeleton = ref(true)

const route = useRoute()
const cid = computed(() => `${route.params.entity_type}:${route.params.entity_id}`)
const isAdmin = computed(() => vehicleStore.items.get(cid.value) === 'admin')

const asOfDate = ref(format(new Date(), 'yyyy-MM-dd'))

///////////////////////////////////////////////////////////////////////////////
// Config
///////////////////////////////////////////////////////////////////////////////

// interface EquityTerms {
//   board_approval_date: Date;
//   type: string;
//   shares_authorized: number;
//   seniority: number;
//   issue_price: number; // Typically, a monetary type would be handled as a number or a custom type.
//   common_conversion_rate: number;
//   pro_rata: boolean;
//   liquidation_preference: boolean;
//   liquidation_preference_multiple: number;
//   participation_rights: boolean;
//   participation_cap: boolean;
//   participation_cap_multiple: number;
//   annual_dividend_rate: number; // Percentage is usually represented as a number (e.g., 0.05 for 5%).
// }

const investmentTypes = {
  equity: 'Equity',
  convertible_note: 'Convertible Note',
  revenue_share: 'Revenue Share',
  safe_note: 'Safe Note',
  loan: 'Loan',
  option: 'Option',
  warrant: 'Warrant',
}

///////////////////////////////////////////////////////////////////////////////
// Utils
///////////////////////////////////////////////////////////////////////////////

// TODO move to a shared location
interface Investment {
  id: string | number
  disbursement: string
  method_label: string
  minimum_annual_return: string
  valuation_cap: string
  accrued_interest: string
  initial_price: string
  initial_shares: number
  initial_value: string
  current_price: string
  current_shares: number
  current_value: string
  investment_type: string
  company: {
    id: string | number
    logo_url?: string
    name?: string
  }
  industry: {
    id?: string | number
    name?: string
  }
  investor: {
    _cid?: string
    _custom_type?: string
    id: string | number
    type: string
    name?: string
  }
  term_sheet: {
    id: string | number
    name?: string
    terms?: string
  }
}

const collectCompaniesFromInvestments = (investments: Investment[]) => {
  return Object.values(
    investments.reduce((acc, investment) => {
      return { ...acc, [investment.company.id]: investment.company }
    }, {}),
  )
}

const collectIndustriesFromInvestments = (investments: Investment[]) => {
  return Object.values(
    investments.reduce((acc, investment) => {
      return { ...acc, [investment.industry.id]: investment.industry }
    }, {}),
  )
}

const collectInvestmentMethodsFromInvestments = (investments: Investment[]) => {
  return Object.values(
    investments.reduce((acc, investment) => {
      return { ...acc, [investment.method_label]: { name: investment.method_label } }
    }, {}),
  )
}

const collectInvestorsFromInvestments = (investments: Investment[]) => {
  return Object.values(
    investments.reduce((acc, investment) => {
      return { ...acc, [investment.investor._cid]: investment.investor }
    }, {}),
  )
}

const getFilterLabel = (filter: string[], total: number) => {
  if (filter.length === total) return 'All'
  // if (filter.length === 1) return filter[0]
  return `${filter.length} selected`
}

const createOptionsFromArray = (items: string[], label: string, value: string) => {
  return items
    ?.map((item) => ({ label: item[label], value: item[value] }))
    ?.sort((a, b) => a.label?.toLowerCase()?.localeCompare(b.label?.toLowerCase()))
}

///////////////////////////////////////////////////////////////////////////////
// Workspace
///////////////////////////////////////////////////////////////////////////////

const workspaceStore = useWorkspaceStore()

///////////////////////////////////////////////////////////////////////////////
// Filtering
///////////////////////////////////////////////////////////////////////////////

const portfolioStore = usePortfolioStore()
portfolioStore.reset() // fixes an issue when navigating between pages

const selectedCompanyIds = ref([])
const selectedIndustryIds = ref([])
const selectedInvestmentTypes = ref([...Object.keys(investmentTypes)])
const selectedInvestorIds = ref([])
// const selectedMethods = ref([])

const clearFilters = () => {
  selectedCompanyIds.value = companyOptions.value.map((option) => option.value)
  selectedIndustryIds.value = industryOptions.value.map((industry) => industry.value)
  selectedInvestmentTypes.value = [...Object.keys(investmentTypes)]
  selectedInvestorIds.value = investorOptions.value.map((option) => option.value)
}

const companies = computed(() => collectCompaniesFromInvestments(portfolioStore.investments))
const industries = computed(() => collectIndustriesFromInvestments(portfolioStore.investments))
const investors = computed(() => collectInvestorsFromInvestments(portfolioStore.investments))

const companyOptions = computed(() => createOptionsFromArray(companies.value, 'name', 'id'))
const industryOptions = computed(() =>
  createOptionsFromArray(industries.value?.filter((item) => item.id), 'name', 'id'),
)
const investmentTypeOptions = computed(() =>
  Object.keys(investmentTypes).map((key) => ({ label: investmentTypes[key], value: key })),
)
const investorOptions = computed(() => createOptionsFromArray(investors.value, 'name', '_cid'))
// const methodOptions = computed(() => createOptions(investmentMethods.value, { label: 'name' }))

watch(companyOptions, () => {
  if (selectedCompanyIds.value.length === 0) {
    selectedCompanyIds.value = companyOptions.value.map((option) => option.value)
  }
})

watch(industryOptions, () => {
  if (selectedIndustryIds.value.length === 0) {
    selectedIndustryIds.value = industryOptions.value.map((industry) => industry.value)
  }
})

watch(investorOptions, () => {
  if (selectedInvestorIds.value.length === 0) {
    selectedInvestorIds.value = investorOptions.value.map((option) => option.value)
  }
})

const filteredInvestments = computed(() => {
  const companyIdsSet = new Set(selectedCompanyIds.value)
  const industryIdsSet = new Set(selectedIndustryIds.value)
  const investmentTypesSet = new Set(selectedInvestmentTypes.value)
  const investorIdsSet = new Set(selectedInvestorIds.value)
  // const methodsSet = new Set(selectedMethods.value)

  if (portfolioStore.investments === undefined) return []

  // prettier-ignore
  return portfolioStore.investments.filter((investment: Investment) => {
    const isCompanyMatch = companyIdsSet.has(investment.company.id)
    const isIndustryMatch = industryIdsSet.has(investment.industry.id || 'other')
    const isInvestmentTypeMatch = investmentTypesSet.has(investment.investment_type)
    const isInvestorMatch = investorIdsSet.has(investment.investor._cid)

    return isCompanyMatch && isIndustryMatch && isInvestmentTypeMatch && isInvestorMatch
  })
})

///////////////////////////////////////////////////////////////////////////////
// Transform
///////////////////////////////////////////////////////////////////////////////

const tableInvestments = computed(() => {
  return Object.values(
    filteredInvestments.value.reduce((acc, investment) => {
      const company = investment.company

      const investment_data = {
        ...investment,
        name: investment.term_sheet?.name,
        roi: roi({
          current_value: toNumber(investment.current_value),
          distributed: toNumber(investment.disbursement),
          initial_value: toNumber(investment.initial_value),
        }),
      }

      // Initialize company if not already in the accumulator
      if (!acc[company.id]) {
        acc[company.id] = {
          ...company,
          accrued_interest: investment.accrued_interest,
          disbursement: investment.disbursement,
          current_price: investment.current_price,
          current_shares: parse(investment.current_shares, 'number'),
          current_value: investment.current_value,
          initial_price: investment.initial_price,
          initial_shares: parse(investment.initial_shares, 'number'),
          initial_value: investment.initial_value,
          investments: [investment_data],
          // minimum_annual_return: investment.minimum_annual_return,
        }
      } else {
        acc[company.id].accrued_interest = sum([acc[company.id].accrued_interest, investment.accrued_interest])
        acc[company.id].disbursement = sum([acc[company.id].disbursement, investment.disbursement])
        acc[company.id].current_shares += parse(investment.current_shares, 'number')
        acc[company.id].current_value = sum([acc[company.id].current_value, investment.current_value])
        acc[company.id].initial_shares += parse(investment.initial_shares, 'number')
        acc[company.id].initial_value = sum([acc[company.id].initial_value, investment.initial_value])
        acc[company.id].investments.push(investment_data)
        // acc[company.id].minimum_annual_return = sum([
        //   acc[company.id].minimum_annual_return,
        //   investment.minimum_annual_return,
        // ]);
      }

      // Compute company ROI
      acc[company.id].roi = roi({
        current_value: toNumber(acc[company.id].current_value),
        distributed: toNumber(acc[company.id].disbursement),
        initial_value: toNumber(acc[company.id].initial_value),
      })

      return acc
    }, {}),
  )
})

///////////////////////////////////////////////////////////////////////////////
// FILTER OPTIONS
///////////////////////////////////////////////////////////////////////////////

// const methodOptions = computed(() => createOptions(methods.value, { label: 'name' }))

const refetchInvestments = async () => {
  skeleton.value = true
  portfolioStore.reset()
  await portfolioStore.fetchInvestments(cid.value, { as_of: asOfDate.value })
  skeleton.value = false
}

onMounted(async () => {
  await vehicleStore.fetchVehicles()
  await portfolioStore.fetchInvestments(cid.value, { as_of: asOfDate.value })
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <TheHeader />
    <TheNav />
    <div class="-mt-7 mb-5 flex justify-end">
      <a :href="`${rails_url()}/investments/new`" v-if="authStore.is_site_or_group_admin">
        <VButton variant="v-blue" class="h-fit">
          <div class="flex items-center gap-2">
            <VIcon name="plus" />
            Add Investment
          </div>
        </VButton>
      </a>
    </div>
    <div>
      <div
        class="mb-10 mt-5 flex flex-wrap items-center gap-3 rounded border-l-4 border-[#85B5C9] bg-[#ABD0DF] p-1 py-3 sm:mt-0 sm:flex-row sm:p-3"
      >
        <VDropdownImproved v-model="selectedCompanyIds" aligned="left" :options="companyOptions" class="z-50">
          <VButton size="md">
            <div class="flex items-center space-x-2">
              <div>Companies: {{ getFilterLabel(selectedCompanyIds, Object.values(companies).length) }}</div>
              <VIcon name="chevron_selector_vertical" />
            </div>
          </VButton>
        </VDropdownImproved>
        <span>where</span>
        <VDropdownImproved v-model="selectedIndustryIds" aligned="left" :options="industryOptions" class="z-50">
          <VButton size="md">
            <div class="flex items-center space-x-2">
              <div>Industries: {{ getFilterLabel(selectedIndustryIds, Object.values(industries).length) }}</div>
              <VIcon name="chevron_selector_vertical" />
            </div>
          </VButton>
        </VDropdownImproved>
        <VDropdownImproved
          v-model="selectedInvestmentTypes"
          aligned="left"
          :options="investmentTypeOptions"
          class="z-50"
        >
          <VButton size="md">
            <div class="flex items-center space-x-2">
              <div>
                Investment types: {{ getFilterLabel(selectedInvestmentTypes, Object.values(investmentTypes).length) }}
              </div>
              <VIcon name="chevron_selector_vertical" />
            </div>
          </VButton>
        </VDropdownImproved>
        <VDropdownImproved v-model="selectedInvestorIds" aligned="left" :options="investorOptions" class="z-50">
          <VButton size="md">
            <div class="flex items-center space-x-2">
              <div>Investors: {{ getFilterLabel(selectedInvestorIds, investorOptions.length) }}</div>
              <VIcon name="chevron_selector_vertical" />
            </div>
          </VButton>
        </VDropdownImproved>

        <span>as of</span>
        <VTextField
          v-model="asOfDate"
          type="date"
          :onChange="refetchInvestments"
          inputClass="py-1.5 text-sm"
          class="-mt-1"
        />

        <div class="sm:flex-grow">
          <VButton class="ml-auto mr-0" :click="clearFilters">Clear Filters</VButton>
        </div>
      </div>

      <PortfolioSection :investments="filteredInvestments" :skeleton="skeleton" :is_portfolio_view_visible="false" />

      <VTable
        :columns="[
          {
            key: 'name',
            name: capitalize(t('shared.name')),
            type: 'string',
            align: 'left',
            fixed: true,
            sorted: true,
            is_visible: true,
          },
          {
            key: 'investor.name',
            name: capitalize(t('shared.investor')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'method_label',
            name: capitalize(t('shared.method')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'initial_value',
            name: capitalize(t('shared.initial value')),
            type: 'currency',
            aggregate: 'sum',
            align: 'right',
            is_visible: true,
            is_accessible: !workspaceStore.isKeyHidden('investments.initial_value', isAdmin),
          },
          {
            key: 'current_value',
            name: capitalize(t('shared.current value')),
            sorted: true,
            type: 'currency',
            aggregate: 'sum',
            align: 'right',
            is_visible: true,
          },
          {
            key: 'disbursement',
            name: capitalize(t('shared.disbursement')),
            sorted: true,
            type: 'currency',
            aggregate: 'sum',
            align: 'right',
            is_visible: true,
          },
          {
            key: 'roi',
            name: capitalize(t('shared.ROI')),
            type: 'percent',
            align: 'center',
            is_visible: true,
            is_accessible: !workspaceStore.isKeyHidden('investments.roi', isAdmin),
          },
          {
            key: 'initial_shares',
            name: capitalize(t('shared.initial shares')),
            type: 'number',
            align: 'right',
            is_visible: false,
            is_accessible: !workspaceStore.isKeyHidden('investments.initial_shares', isAdmin),
          },
          {
            key: 'current_shares',
            name: capitalize(t('shared.current shares')),
            type: 'number',
            align: 'right',
            is_visible: false,
          },
          {
            key: 'initial_price',
            name: capitalize(t('shared.initial price')),
            type: 'currency',
            align: 'right',
            is_visible: false,
            is_accessible: !workspaceStore.isKeyHidden('investments.initial_price', isAdmin),
          },
          {
            key: 'current_price',
            name: capitalize(t('shared.current price')),
            type: 'currency',
            align: 'right',
            is_visible: false,
          },
          {
            key: 'accrued_interest',
            name: capitalize(t('shared.accrued interest')),
            type: 'currency',
            align: 'right',
            is_visible: false,
          },
          // {
          //   key: 'minimum_annual_return',
          //   name: capitalize(t('shared.minimum annual return')),
          //   type: 'currency',
          //   align: 'right',
          //   is_visible: false,
          // },
          // {
          //   key: 'valuation_cap',
          //   name: capitalize(t('shared.valuation cap')),
          //   type: 'currency',
          //   align: 'right',
          //   is_visible: false,
          // },
          {
            key: 'industry.name',
            name: capitalize(t('shared.industry')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'investment_type',
            name: capitalize(t('shared.investment type')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'terms.expiration_date',
            name: capitalize(t('shared.expiration date')),
            type: 'date',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'terms.annual_dividend_rate',
            name: capitalize(t('shared.annual dividend rate')),
            type: 'number',
            align: 'right',
            is_visible: false,
          },
          {
            key: 'terms.board_approval_date',
            name: capitalize(t('shared.board approval date')),
            type: 'date',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'terms.type',
            name: capitalize(t('shared.type')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'terms.shares_authorized',
            name: capitalize(t('shared.shares authorized')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'terms.seniority',
            name: capitalize(t('shared.seniority')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'terms.issue_price',
            name: capitalize(t('shared.issue price')),
            type: 'number',
            align: 'right',
            is_visible: false,
          },
          {
            key: 'terms.common_conversion_rate',
            name: capitalize(t('shared.common conversion rate')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'terms.pro_rata',
            name: capitalize(t('shared.pro rata')),
            type: 'boolean',
            align: 'center',
            is_visible: false,
          },
          {
            key: 'terms.liquidation_preference',
            name: capitalize(t('shared.liquidation preference')),
            type: 'boolean',
            align: 'center',
            is_visible: false,
          },
          {
            key: 'terms.liquidation_preference_multiple',
            name: capitalize(t('shared.liquidation preference multiple')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'terms.participation_rights',
            name: capitalize(t('shared.participation rights')),
            type: 'boolean',
            align: 'center',
            is_visible: false,
          },
          {
            key: 'terms.participation_cap',
            name: capitalize(t('shared.participation cap')),
            type: 'boolean',
            align: 'center',
            is_visible: false,
          },
          {
            key: 'terms.participation_cap_multiple',
            name: capitalize(t('shared.participation cap multiple')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'terms.discount_rate',
            name: capitalize(t('shared.discount rate')),
            type: 'percent',
            align: 'right',
            is_visible: false,
          },
          {
            key: 'terms.first_payment_date',
            name: capitalize(t('shared.first payment date')),
            type: 'date',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'terms.interest_rate',
            name: capitalize(t('shared.interest rate')),
            type: 'percent',
            align: 'right',
            is_visible: false,
          },
          {
            key: 'terms.interest_type',
            name: capitalize(t('shared.interest type')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'terms.compounding_frequency',
            name: capitalize(t('shared.compounding frequency')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'terms.converts_to',
            name: capitalize(t('shared.converts to')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'terms.maturity_date',
            name: capitalize(t('shared.maturity date')),
            type: 'date',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'terms.annual_share_of_revenue',
            name: capitalize(t('shared.annual share of revenue')),
            type: 'number',
            align: 'right',
            is_visible: false,
          },
          {
            key: 'terms.minimum_annual_return_percentage',
            name: capitalize(t('shared.minimum annual return percentage')),
            type: 'percent',
            align: 'right',
            is_visible: false,
          },
          {
            key: 'terms.term_value',
            name: capitalize(t('shared.term value')),
            type: 'number',
            align: 'right',
            is_visible: false,
          },
          {
            key: 'terms.term_units',
            name: capitalize(t('shared.term units')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'terms.payment_frequency',
            name: capitalize(t('shared.payment frequency')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'terms.option_type',
            name: capitalize(t('shared.option type')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'terms.shares_allocated',
            name: capitalize(t('shared.shares allocated')),
            type: 'number',
            align: 'right',
            is_visible: false,
          },
          {
            key: 'terms.exercise_price',
            name: capitalize(t('shared.exercise price')),
            type: 'number',
            align: 'right',
            is_visible: false,
          },
          {
            key: 'terms.vesting_period_months',
            name: capitalize(t('shared.vesting period months')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'terms.cliff_months',
            name: capitalize(t('shared.cliff months')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'terms.after_cliff_vest_months',
            name: capitalize(t('shared.after cliff vest months')),
            type: 'string',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'terms.vest_start_date',
            name: capitalize(t('shared.vest start date')),
            type: 'date',
            align: 'left',
            is_visible: false,
          },
          {
            key: 'terms.expiration_period_years',
            name: capitalize(t('shared.expiration period years')),
            type: 'number',
            align: 'right',
            is_visible: false,
          },
        ]"
        :items="tableInvestments"
        :name="`portfolio`"
        :skeleton="skeleton"
        sub_item_key="investments"
        :slots="['name']"
        :expand="true"
      >
        <template #name="{ item }">
          <div class="flex items-center gap-1">
            <VAvatar
              :image="item.logo_url ? decodeURIComponent(item.logo_url) : null"
              :initials="item.initials"
              size="sm"
            />
            <a class="hyperlink" :href="`${rails_url()}/companies/${item.id}`">
              {{ item.name }}
            </a>
          </div>
        </template>
        <template #subgroup.name="{ item }">
          <div class="inline-flex items-center space-x-2">
            <a
              class="hyperlink"
              :href="`${rails_url()}/companies/${item.company.id}/terms/${get(item, 'term_sheet.id')}`"
              v-if="authStore.is_site_or_group_admin"
            >
              {{ item.name }}
            </a>
            <span v-else>{{ item.name }}</span>
            <span class="text-gray-500">/</span>
            <span>{{ item.investor.name }}</span>
          </div>
        </template>
      </VTable>
    </div>
  </TheLayout>
</template>
