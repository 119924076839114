<script setup lang="ts">
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import OtherEntityNav from '../components/other-entity-nav.vue'
import OtherEntityHeader from '../components/other-entity-header.vue'
import { computed, onMounted, ref } from 'vue'
import { usePortfolioStore } from '@/modules/investing/stores/portfolio-store'
import { useRoute } from 'vue-router'
import { rails_url } from '@/modules/shared/utils/rails'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import { Portfolio, VButton, VIcon } from '@/modules/shared/components'
import { useInvestingVehicleStore } from '../stores/vehicle-store'

const route = useRoute()
const authStore = useAuthStore()
const portfolioStore = usePortfolioStore()
const vehicleStore = useInvestingVehicleStore()

const cid = computed(() => `funding-entity:${route.params.other_entity_id}`)
const isAdmin = computed(() => vehicleStore.items.get(cid.value) === 'admin')
const skeleton = ref(true)
const portfolioUtil = ref(null)

onMounted(async () => {
  await Promise.all([
    portfolioStore.fetchPortfolio({
      _cid: `funding-entity:${route.params.other_entity_id}`,
      as_of_date: new Date(),
    }),
  ])
  portfolioUtil.value.fetch = {
    call: portfolioStore.fetchPortfolio,
    payload: { _cid: `funding-entity:${route.params.other_entity_id}`, as_of_date: new Date() },
  }
  portfolioUtil.value.setFilterAll()
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <OtherEntityHeader />
    <OtherEntityNav />
    <div class="-mt-7 mb-5 flex justify-end">
      <a :href="`${rails_url()}/investments/new`" v-if="authStore.is_site_or_group_admin">
        <VButton size="md" variant="v-blue">
          <div class="flex items-center gap-2">
            <VIcon name="plus" />
            Add Investment
          </div>
        </VButton>
      </a>
    </div>
    <Portfolio
      v-model="portfolioUtil"
      :cid="`funding-entity:${route.params.other_entity_id}`"
      :investments="portfolioStore.investments"
      :is_column_method_visible="true"
      :is_header_visible="false"
      :skeleton="skeleton"
      title="Portfolio"
      :isAdmin="isAdmin"
    />
  </TheLayout>
</template>
