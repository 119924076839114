<script setup lang="ts">
import Big from 'big.js'
import { flatten } from 'flat'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { format } from 'date-fns'
import { capitalize, get } from 'lodash'
import { useFocus, useMagicKeys } from '@vueuse/core'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useExtendedI18n } from '@/i18n'
import { useCan } from '@/modules/auth/composables/use-can'
import { useAuthStore } from '@/modules/auth/stores/auth-store'
import {
  VButton,
  VIcon,
  VModal,
  VSection,
  VTable,
  VTextField,
  ActionsMenu,
  ActionItem,
  ActionsGroup,
} from '@/modules/shared/components'
import { useModal } from '@/modules/shared/composables/use-modal'
import TheLayout from '@/modules/shared/layouts/the-layout.vue'
import { createOptions, markDisabledOptions } from '@/modules/shared/utils/form'
import { calculate_aggregate, parse, format as data_format } from '@/modules/shared/utils/v-table'
import OwnershipTransferSummary from '../components/ownership-transfer-summary.vue'
import TheHeader from '../components/the-header.vue'
import TheNav from '../components/the-nav.vue'
import VSelect from '../components/v-select.vue'
import { useCommitmentStore } from '../stores/commitment-store'
import { useEntityStore } from '../stores/entity-store'
import { useInvestorStore } from '@/modules/entity/stores/investor-store'
import { useInvestingVehicleStore } from '../stores/vehicle-store'
import { ownership_transfer_form_initial_state, useOwnershipTransferStore } from '../stores/ownership-transfer-store'
import { getOwnershipTransferSummary } from '../utils/ownership-transfer'
import { initialMoney } from '@/modules/shared/utils/money'
import { investorPath } from '../utils/investor'

///////////////////////////////////////////////////////////////////////////////
// Utils
///////////////////////////////////////////////////////////////////////////////

const createEntity = (route) => ({
  id: route.params.entity_id,
  type: route.params.entity_type,
})

///////////////////////////////////////////////////////////////////////////////

const { can } = useCan()
const { n, t } = useExtendedI18n()
const route = useRoute()
const cid = computed(() => `${route.params.entity_type}:${route.params.entity_id}`)
const entityRoute = createEntity(route)

///////////////////////////////////////////////////////////////////////////////
// Authorization
///////////////////////////////////////////////////////////////////////////////

const vehicleStore = useInvestingVehicleStore()
const isAdmin = computed(() => vehicleStore.items.get(cid.value) === 'admin')

///////////////////////////////////////////////////////////////////////////////
// Misc.
///////////////////////////////////////////////////////////////////////////////

const authStore = useAuthStore()
const commitmentStore = useCommitmentStore()
const entityStore = useEntityStore()
const investorStore = useInvestorStore()
const ownershipTransferStore = useOwnershipTransferStore()

const currentEntity = ref(null)

const investorOptions = computed(() => {
  const disabledInvestors = [
    ...commitmentStore.commitments.map((c) => c.investor._cid),
    `${route.params.entity_type}:${route.params.entity_id}`, // current entity can't invest in itself
  ]

  return markDisabledOptions(createOptions(investorStore.state.items, { label: 'name' }), disabledInvestors)
})

const investorTranferOptions = computed(() => {
  const disabledInvestors = [transferOwnership.value.buyer_investor_id]

  return markDisabledOptions(createOptions(investorStore.state.items, { label: 'name' }), disabledInvestors)
})
///////////////////////////////////////////////////////////////////////////////
// Add or modify commitment
///////////////////////////////////////////////////////////////////////////////

const autofocus = ref()
const { focused } = useFocus(autofocus)
const { n: nKey } = useMagicKeys()

const commitmentFormModal = useModal()

const initialState = {
  id: null,
  amount: null,
  carried_interest_percentage: null,
  date: format(new Date(), 'yyyy-MM-dd'),
  investor_id: null,
  management_fee_percentage: null,
  preferred_return_percentage: null,
}

watch(currentEntity, () => {
  initialState.carried_interest_percentage = currentEntity.value.carried_interest_percentage
  initialState.management_fee_percentage = currentEntity.value.management_fee_percentage
  initialState.preferred_return_percentage = currentEntity.value.preferred_return_percentage
})

const rules = {
  amount: { required },
  date: { required },
  investor_id: { required },
}
const commitment = ref({ ...initialState })
const v$ = useVuelidate(rules, commitment, { $lazy: true })

function showCommitmentForm(data?: any) {
  // reset
  v$.value.$reset()
  commitment.value = { ...initialState }

  // assign
  if (data) {
    commitment.value.id = data.id
    commitment.value.investor_id = get(data, 'investor._cid')
    commitment.value.amount = parse(get(data, 'capital_committed.amount'), 'currency')
    commitment.value.carried_interest_percentage = data.carried_interest_percentage
      ? (parse(data.carried_interest_percentage, 'number') as number) * 100
      : null
    commitment.value.management_fee_percentage = data.management_fee_percentage
      ? (parse(data.management_fee_percentage, 'number') as number) * 100
      : null
    commitment.value.preferred_return_percentage = data.preferred_return_percentage
      ? (parse(data.preferred_return_percentage, 'number') as number) * 100
      : null

    let date
    if (data.date) {
      const date_in_timezone = new Date(data.date)
      date = new Date(date_in_timezone.getTime() + date_in_timezone.getTimezoneOffset() * 60 * 1000)
    } else {
      date = new Date()
    }

    commitment.value.date = format(date, 'yyyy-MM-dd')
  }

  focused.value = true
  commitmentFormModal.open()
}

const loading = ref(false)

async function saveCommitment() {
  const valid = await v$.value.$validate()
  if (!valid) return

  loading.value = true

  if (commitment.value.management_fee_percentage) {
    commitment.value.management_fee_percentage = parseFloat(commitment.value.management_fee_percentage) / 100
  }

  if (commitment.value.carried_interest_percentage) {
    commitment.value.carried_interest_percentage = parseFloat(commitment.value.carried_interest_percentage) / 100
  }

  if (commitment.value.preferred_return_percentage) {
    commitment.value.preferred_return_percentage = parseFloat(commitment.value.preferred_return_percentage) / 100
  }

  const data = {
    date: commitment.value.date,
    investor_id: commitment.value.investor_id,
    capital: commitment.value.amount,
    carried_interest_percentage: commitment.value.carried_interest_percentage,
    management_fee_percentage: commitment.value.management_fee_percentage,
    preferred_return_percentage: commitment.value.preferred_return_percentage,
  }

  if (commitment.value.id) {
    await commitmentStore.updateCommitment(commitment.value.id, data, route.params.entity_type, route.params.entity_id)
    commitmentFormModal.close()
  } else {
    await commitmentStore.addCommitment(data, route.params.entity_type, route.params.entity_id)
    v$.value.$reset()
    commitment.value = { ...initialState }
    focused.value = true
  }

  loading.value = false
}

///////////////////////////////////////////////////////////////////////////////
// Remove commitment
///////////////////////////////////////////////////////////////////////////////

const removeCommitment = async (commitment) => {
  if (commitment.investments_count !== 0 && !authStore.is_site_or_group_admin)
    return window.confirm('Please delete existing investments first or contact customer support.')
  if (commitment.has_ownership_transfers) return window.confirm('Investor has existing transfers')
  if (!window.confirm('Are you sure?')) return
  await commitmentStore.removeCommitment(commitment.id, route.params.entity_type, route.params.entity_id)
}

///////////////////////////////////////////////////////////////////////////////
// Transfer
///////////////////////////////////////////////////////////////////////////////
const transferCommitmentFormModal = useModal()

const transferFormInitialState = {
  commitment_id: null,
  from_investor_id: null,
  to_investor_id: null,
}

const transferFormRules = {
  commitment_id: { required },
  from_investor_id: { required },
  to_investor_id: { required },
}

const transfer = ref({ ...transferFormInitialState })
const vt$ = useVuelidate(transferFormRules, transfer, { $lazy: true })

function showTransferForm(item: any) {
  // reset
  vt$.value.$reset()
  transfer.value = { ...transferFormInitialState }

  // assign
  transfer.value.commitment_id = item.id
  transfer.value.from_investor_id = `${item['investor.ruby_type']}:${item['investor.id']}`
  transfer.value.to_investor_id = null

  transferCommitmentFormModal.open()
}

async function transferCommitment() {
  const valid = await vt$.value.$validate()
  if (!valid) return
  await commitmentStore.transferCommitment(
    transfer.value.commitment_id,
    transfer.value,
    route.params.entity_type,
    route.params.entity_id,
  )
  transferCommitmentFormModal.close()
}

///////////////////////////////////////////////////////////////////////////////
// Ownership Transfer
///////////////////////////////////////////////////////////////////////////////
const transferOwnershipFormModal = useModal()
const transferOwnership = ref({ ...ownership_transfer_form_initial_state })
const transferOwnershipFormRules = {
  buyer_investor_id: { required },
  seller_investor_id: { required },
  transfer_percentage: { required },
  amount_paid: { required },
  date: { required },
}
const vto$ = useVuelidate(transferOwnershipFormRules, transferOwnership, { $lazy: true })

function showTransferOwnershipForm(item: any) {
  vto$.value.$reset()
  transferOwnership.value = { ...ownership_transfer_form_initial_state }
  transferOwnership.value.seller_investor_id = get(item, 'investor._cid')
  transferOwnershipFormModal.open()
}

const ownershipTransferFormSummary = computed(() => {
  const seller_cid = transferOwnership.value.seller_investor_id
  const seller = commitmentStore.items.get(seller_cid)
  const buyer_cid = transferOwnership.value.buyer_investor_id
  const buyer = commitmentStore.items.get(buyer_cid)
  const transferred_percent = (transferOwnership.value.transfer_percentage || 0) / 100

  return getOwnershipTransferSummary(
    seller,
    buyer,
    transferred_percent,
    transferOwnership.value.amount_paid,
    transferOwnership.value.carried_interest_amount,
    transferOwnership.value.other_fee,
    currentEntity.value.currency,
  )
})

const canSellerTransferOwnership = computed(() => {
  if (!transferOwnership.value.seller_investor_id) return true
  const seller = commitmentStore.items.get(transferOwnership.value.seller_investor_id)

  return !seller.has_scheduled_calls && !seller.has_outstanding_invoices
})

async function transferOwnershipSubmit() {
  if (!canSellerTransferOwnership) return

  const valid = await vto$.value.$validate()
  if (!valid) return
  await ownershipTransferStore.transferOwnership(
    {
      buyer_investor_id: transferOwnership.value.buyer_investor_id,
      seller_investor_id: transferOwnership.value.seller_investor_id,
      transfer_percentage: transferOwnership.value.transfer_percentage,
      amount_paid: transferOwnership.value.amount_paid || 0,
      carried_interest_amount: transferOwnership.value.carried_interest_amount || 0,
      other_fee: transferOwnership.value.other_fee || 0,
      date: transferOwnership.value.date,
    },
    route.params.entity_type,
    route.params.entity_id,
  )
  await commitmentStore.fetchCommitments(route.params.entity_type, route.params.entity_id)
  transferOwnershipFormModal.close()
}

///////////////////////////////////////////////////////////////////////////////
// Assign Ownership
///////////////////////////////////////////////////////////////////////////////
const assignOwnershipFormModal = useModal()
const assign_ownership_intial_state = {
  id: null,
  investor_id: null,
  ownership_override: null,
}
const assign_ownership_form = ref({ ...assign_ownership_intial_state })
const vao$ = useVuelidate({}, assign_ownership_form, { $lazy: true })
const openAssignOwnershipForm = (item) => {
  assign_ownership_form.value.id = item.id
  assign_ownership_form.value.investor_id = `${get(item, 'investor.ruby_type')}:${get(item, 'investor.id')}`
  if (item.ownership_override)
    assign_ownership_form.value.ownership_override = (parse(item.ownership_override, 'number') as number) * 100
  assignOwnershipFormModal.open()
}
const saveOwnership = async () => {
  const valid = await vao$.value.$validate()
  if (!valid) return

  if (parseFloat(assign_ownership_form.value.ownership_override || -1) >= 0) {
    assign_ownership_form.value.ownership_override = parseFloat(assign_ownership_form.value.ownership_override) / 100
  } else {
    assign_ownership_form.value.ownership_override = null
  }
  await commitmentStore.updateOwnership(
    assign_ownership_form.value.id,
    assign_ownership_form.value,
    route.params.entity_type,
    route.params.entity_id,
  )

  assignOwnershipFormModal.close()
}

///////////////////////////////////////////////////////////////////////////////
// Mounted
///////////////////////////////////////////////////////////////////////////////

const skeleton = ref(true)

onMounted(async () => {
  await commitmentStore.fetchCommitments(route.params.entity_type, route.params.entity_id)
  await entityStore.fetchEntityInfo({ type: entityRoute.type as string, id: entityRoute.id as string })
  await investorStore.reset()
  await investorStore.list()
  await vehicleStore.fetchVehicles()
  await investorStore.listProfiles()

  currentEntity.value = entityStore.items.get(`${entityRoute.type}:${entityRoute.id}`)
  skeleton.value = false
})
</script>

<template>
  <TheLayout>
    <TheHeader />
    <TheNav />
    <div class="-mt-7 mb-5 flex justify-end">
      <VButton :click="showCommitmentForm" size="md" variant="v-blue" v-if="isAdmin">
        <div class="mr-1 flex items-center space-x-2">
          <div><VIcon name="plus" /></div>
          <div>{{ capitalize(t('shared.add investor', 0)) }}</div>
          <!-- <div class="rounded bg-white/20 px-1">N</div> -->
        </div>
      </VButton>
    </div>
    <VTable
      :columns="[
        {
          key: 'investor.name',
          name: capitalize(t('shared.investor')),
          sorted: true,
          type: 'string',
          align: 'left',
          fixed: true,
          is_visible: true,
        },
        {
          key: 'date',
          name: capitalize(t('shared.date')),
          type: 'date',
          align: 'left',
          is_visible: true,
        },
        {
          key: 'investor.email',
          name: capitalize(t('shared.email')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'ownership',
          name: capitalize(t('shared.ownership')),
          type: 'percent',
          align: 'right',
          is_visible: false,
          aggregate: 'sum',
        },
        {
          key: 'ownership_computed',
          name: capitalize(t('shared.ownership computed')),
          type: 'percent',
          align: 'right',
          is_visible: false,
          is_accessible: authStore.is_site_admin,
        },
        {
          key: 'ownership_override',
          name: capitalize(t('shared.ownership override')),
          type: 'percent',
          align: 'right',
          is_visible: false,
          is_accessible: authStore.is_site_admin,
        },
        {
          key: 'ownership_by_commitment_amount',
          name: capitalize(t('shared.ownership by commitment')),
          type: 'percent',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'capital_committed',
          name: capitalize(t('shared.committed')),
          type: 'currency',
          aggregate: 'sum',
          align: 'right',
          is_visible: true,
        },
        {
          key: 'capital_called',
          name: capitalize(t('shared.called')),
          type: 'currency',
          aggregate: 'sum',
          align: 'right',
          is_visible: true,
        },
        {
          key: 'management_fees',
          name: capitalize(t('shared.management fee')),
          type: 'currency',
          aggregate: 'sum',
          align: 'right',
          is_visible: true,
        },
        {
          key: 'other_fees',
          name: capitalize(t('shared.other fee')),
          type: 'currency',
          aggregate: 'sum',
          align: 'right',
          is_visible: true,
        },
        {
          key: 'total_called',
          name: capitalize(t('shared.called (with fees)')),
          type: 'currency',
          aggregate: 'sum',
          align: 'right',
          is_visible: true,
        },
        {
          key: 'commitment_remaining',
          name: capitalize(t('shared.commitment remaining')),
          type: 'currency',
          aggregate: 'sum',
          align: 'right',
          is_visible: true,
        },
        {
          key: 'management_fee_percentage',
          name: capitalize(t('shared.management fee')),
          type: 'percent',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'carried_interest_percentage',
          name: capitalize(t('shared.carried interest')),
          type: 'percent',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'preferred_return_percentage',
          name: capitalize(t('shared.preferred return')),
          type: 'percent',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'net_distributed',
          name: capitalize(t('shared.distributed')),
          type: 'currency',
          aggregate: 'sum',
          align: 'right',
          is_visible: true,
        },
        {
          key: 'total_fees',
          name: capitalize(t('shared.total fee')),
          type: 'currency',
          aggregate: 'sum',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'cash_position',
          name: capitalize(t('shared.cash position')),
          type: 'currency',
          aggregate: 'sum',
          align: 'right',
          is_visible: false,
        },
        {
          key: 'investor.investing_name',
          name: capitalize(t('shared.legal name')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'investor.tax_id',
          name: `${t('shared.SSN')} / ${t('shared.EIN')}`,
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'investor.type',
          name: capitalize(t('shared.type')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'address.phone',
          name: capitalize(t('shared.phone number')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'address.street1',
          name: capitalize(t('shared.line 1')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'address.street2',
          name: capitalize(t('shared.line 2')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'address.city',
          name: capitalize(t('shared.city')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'address.state',
          name: capitalize(t('shared.state')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'address.zip',
          name: capitalize(t('shared.postal code')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'address.country',
          name: capitalize(t('shared.country')),
          type: 'string',
          align: 'left',
          is_visible: false,
        },
        {
          key: 'actions',
          name: '',
          type: 'actions',
          align: 'right',
          is_visible: true,
        },
      ]"
      :items="commitmentStore.commitments"
      :name="`${route.params.entity_type}-${route.params.entity_id}-investors`"
      :skeleton="skeleton"
      :slots="['actions', 'investor.name', 'ownership']"
    >
      <template #investor.name="{ item }">
        <RouterLink
          class="text-[#3b88af] underline decoration-[#3b88af]/50 hover:text-gray-900 hover:decoration-gray-900/50"
          :to="investorPath(get(item, 'investor._cid'))"
        >
          {{ get(item, 'investor.name') }}
        </RouterLink>
      </template>
      <template #ownership="{ item }">
        {{ data_format(flatten(item)['ownership'], 'percent') }}
      </template>
      <template #tfoot.ownership="{ items, column }">
        <div :class="{ 'text-red-600': calculate_aggregate(items, column) > 1 }">
          {{ data_format(calculate_aggregate(items, column), 'percent') }}
        </div>
      </template>
      <template #actions="{ item }" v-if="isAdmin">
        <ActionsMenu>
          <ActionsGroup>
            <ActionItem tag="button" :text="capitalize(t('shared.modify'))" @click="() => showCommitmentForm(item)" />
            <ActionItem tag="button" :text="capitalize(t('shared.reassign'))" @click="() => showTransferForm(item)" />
            <ActionItem tag="button" :text="capitalize(t('shared.remove'))" @click="() => removeCommitment(item)" />
            <ActionItem
              tag="button"
              :text="capitalize(t('shared.transfer'))"
              @click="() => showTransferOwnershipForm(item)"
              :disabled="new Big(get(item, 'capital_committed.amount')).eq(0)"
            />
            <ActionItem
              tag="button"
              :text="capitalize(t('shared.assign ownership'))"
              @click="() => openAssignOwnershipForm(item)"
            />
          </ActionsGroup>
        </ActionsMenu>
      </template>
    </VTable>

    <VModal :modalStore="commitmentFormModal">
      <template #main>
        <VSection :label="commitment.id ? 'Modify investor' : 'Add investor'">
          <form @submit.prevent="saveCommitment">
            <div class="mt-6 space-y-3">
              <VTextField
                v-model="commitment.date"
                :label="capitalize(t('shared.date'))"
                property="date"
                ref="autofocus"
                type="date"
                :v$="v$"
              />
              <VSelect
                v-model="commitment.investor_id"
                :disabled="commitment.id"
                :label="capitalize(t('shared.investor'))"
                :options="investorOptions"
                property="investor_id"
                :v$="v$"
              />
              <VTextField
                v-model="commitment.amount"
                :label="capitalize(t('shared.commitment'))"
                :placeholder="n(initialMoney, 'currency')"
                property="amount"
                :v$="v$"
              />
              <div>
                <hr class="my-8 h-1 bg-gray-100" />
              </div>
              <VTextField
                v-model="commitment.carried_interest_percentage"
                :label="capitalize(t('shared.carried interest'))"
                :placeholder="n(0, 'percent')"
                property="carried_interest_percentage"
                :v$="v$"
              />
              <VTextField
                v-model="commitment.management_fee_percentage"
                :label="capitalize(t('shared.management fee'))"
                :placeholder="n(0, 'percent')"
                property="management_fee_percentage"
                :v$="v$"
              />
              <VTextField
                v-model="commitment.preferred_return_percentage"
                :label="capitalize(t('shared.preferred return'))"
                :placeholder="n(0, 'percent')"
                property="preferred_return_percentage"
                :v$="v$"
              />
            </div>
          </form>
        </VSection>
      </template>
      <template #footer>
        <div class="flex items-center justify-between space-x-3">
          <VButton :click="commitmentFormModal.close" size="lg">{{ capitalize(t('shared.close')) }}</VButton>
          <VButton :click="saveCommitment" class="w-full" :loading="loading" size="lg" variant="primary">
            <span v-if="commitment.id">{{ capitalize(t('shared.modify investor')) }}</span>
            <span v-else>{{ capitalize(t('shared.add investor')) }}</span>
          </VButton>
        </div>
      </template>
    </VModal>
    <VModal :modalStore="transferCommitmentFormModal">
      <template #main>
        <VSection label="Transfer Commitment">
          <div class="space-y-3">
            <VSelect
              v-model="transfer.from_investor_id"
              :label="capitalize(t('shared.from'))"
              :options="investorOptions"
              property="from_investor_id"
              :v$="vt$"
              :disabled="true"
            />
            <VSelect
              v-model="transfer.to_investor_id"
              :label="capitalize(t('shared.to'))"
              :options="investorOptions"
              property="to_investor_id"
              :v$="vt$"
            />
          </div>
        </VSection>
      </template>
      <template #footer>
        <VButton :click="transferCommitment" variant="primary" size="lg" class="w-full">Transfer</VButton>
      </template>
    </VModal>
    <VModal :modalStore="transferOwnershipFormModal" id="transferOwnershipFormModal" :has_footer="false">
      <template #main>
        <div class="-mx-6 -my-6 border-b-[1px] border-gray-300 px-6 py-3 text-lg font-semibold">Transfer Ownership</div>
        <div class="mt-9 flex h-[calc(100%-1.8rem)] gap-5">
          <div class="flex flex-col gap-3 pb-6">
            <VSection class="w-[30rem] flex-grow">
              <div class="space-y-3">
                <VSelect
                  v-model="transferOwnership.seller_investor_id"
                  :label="capitalize(t('shared.who is the seller?'))"
                  :options="investorTranferOptions"
                  property="seller_investor_id"
                  :v$="vto$"
                  :disabled="true"
                />
                <VSelect
                  v-model="transferOwnership.buyer_investor_id"
                  :label="capitalize(t('shared.who is the buyer?'))"
                  :options="investorTranferOptions"
                  property="buyer_investor_id"
                  :v$="vto$"
                />
                <VTextField
                  v-model="transferOwnership.date"
                  :label="capitalize(t('shared.date'))"
                  type="date"
                  property="date"
                  :v$="vto$"
                />
                <VTextField
                  v-model="transferOwnership.transfer_percentage"
                  :label="capitalize(t('shared.what % of the seller’s position is being bought?'))"
                  :placeholder="n(0, 'percent')"
                  property="transfer_percentage"
                  type="number"
                  :v$="vto$"
                />
                <VTextField
                  v-model="transferOwnership.amount_paid"
                  :label="capitalize(t('shared.how much is being paid for it?'))"
                  :placeholder="n(initialMoney, 'currency')"
                  property="amount_paid"
                  type="number"
                  :v$="vto$"
                />
                <VTextField
                  v-model="transferOwnership.carried_interest_amount"
                  :label="capitalize(t('shared.is there any carried interest being paid?'))"
                  :placeholder="n(initialMoney, 'currency')"
                  property="carried_interest_amount"
                  type="number"
                  :v$="vto$"
                />
                <VTextField
                  v-model="transferOwnership.other_fee"
                  :label="capitalize(t('shared.any other fees being paid as part of this transaction?'))"
                  :placeholder="n(initialMoney, 'currency')"
                  property="other_fee"
                  type="number"
                  :v$="vto$"
                />
              </div>
            </VSection>
            <div>
              <div class="mb-1 text-center text-sm text-red-500" v-if="!canSellerTransferOwnership">
                Seller has scheduled calls or outstanding invoices, cannot make the transfer
              </div>
              <VButton
                :disabled="!canSellerTransferOwnership"
                :click="transferOwnershipSubmit"
                variant="primary"
                size="lg"
                class="w-full"
                >Transfer Ownership</VButton
              >
            </div>
          </div>
          <div class="-my-3 -mr-6 w-[32.5rem] overflow-y-auto bg-gray-100 px-5 py-3">
            <OwnershipTransferSummary :ownership_transfer="ownershipTransferFormSummary" />
          </div>
        </div>
      </template>
    </VModal>
    <VModal :modalStore="assignOwnershipFormModal">
      <template #main>
        <VSection label="Assign Ownership">
          <form @submit.prevent="saveOwnership">
            <div class="mt-6 space-y-3">
              <VTextField
                v-model="assign_ownership_form.ownership_override"
                :label="capitalize(t('shared.ownership override'))"
                :placeholder="n(0, 'percent')"
                property="ownership_override"
                :v$="v$"
              />
            </div>
          </form>
        </VSection>
      </template>
      <template #footer>
        <div class="flex items-center justify-between space-x-3">
          <VButton :click="assignOwnershipFormModal.close" size="lg">{{ capitalize(t('shared.close')) }}</VButton>
          <VButton :click="saveOwnership" class="w-full" size="lg" variant="primary">
            <span>{{ capitalize(t('shared.confirm change', 0)) }}</span>
          </VButton>
        </div>
      </template>
    </VModal>
  </TheLayout>
</template>

<style>
table.seller-summary td,
table.seller-summary th,
table.buyer-summary td {
  @apply whitespace-nowrap pb-2 pr-5 text-left first:w-[1%];
}

#transferOwnershipFormModal .dialog-panel-container {
  @apply !w-fit !max-w-fit;
}

#transferOwnershipFormModal .dialog-panel-container .main-div {
  @apply !overflow-y-hidden;
}
</style>
