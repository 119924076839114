export const investorPath = (cid) => {
  const type = cid.split(':')[0]
  const id = cid.split(':')[1]
  switch (type) {
    case 'funding-entity':
      return { name: 'investing.other-entity.overview', params: { other_entity_id: id } }
      break
    case 'individual':
      return { name: 'investing.individual-overview', params: { individual_id: id } }
      break
    default:
      return { name: 'investing.entity-overview', params: { entity_type: type, entity_id: id } }
      break
  }
}
